@font-face {
    font-family: 'Campton Book';
    src: url('Campton-Book.eot');
    src: local('Campton Book'), local('Campton-Book'),
        url('Campton-Book.eot?#iefix') format('embedded-opentype'),
        url('Campton-Book.woff2') format('woff2'),
        url('Campton-Book.woff') format('woff'),
        url('Campton-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-Light.eot');
    src: local('Campton Light'), local('Campton-Light'),
        url('Campton-Light.eot?#iefix') format('embedded-opentype'),
        url('Campton-Light.woff2') format('woff2'),
        url('Campton-Light.woff') format('woff'),
        url('Campton-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Campton Book';
    src: url('Campton-BookItalic.eot');
    src: local('Campton Book Italic'), local('Campton-BookItalic'),
        url('Campton-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-BookItalic.woff2') format('woff2'),
        url('Campton-BookItalic.woff') format('woff'),
        url('Campton-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-Medium.eot');
    src: local('Campton Medium'), local('Campton-Medium'),
        url('Campton-Medium.eot?#iefix') format('embedded-opentype'),
        url('Campton-Medium.woff2') format('woff2'),
        url('Campton-Medium.woff') format('woff'),
        url('Campton-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-ExtraBold.eot');
    src: local('Campton ExtraBold'), local('Campton-ExtraBold'),
        url('Campton-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Campton-ExtraBold.woff2') format('woff2'),
        url('Campton-ExtraBold.woff') format('woff'),
        url('Campton-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-ThinItalic.eot');
    src: local('Campton Thin Italic'), local('Campton-ThinItalic'),
        url('Campton-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-ThinItalic.woff2') format('woff2'),
        url('Campton-ThinItalic.woff') format('woff'),
        url('Campton-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-ExtraLightItalic.eot');
    src: local('Campton ExtraLight Italic'), local('Campton-ExtraLightItalic'),
        url('Campton-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-ExtraLightItalic.woff2') format('woff2'),
        url('Campton-ExtraLightItalic.woff') format('woff'),
        url('Campton-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-Black.eot');
    src: local('Campton Black'), local('Campton-Black'),
        url('Campton-Black.eot?#iefix') format('embedded-opentype'),
        url('Campton-Black.woff2') format('woff2'),
        url('Campton-Black.woff') format('woff'),
        url('Campton-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-MediumItalic.eot');
    src: local('Campton Medium Italic'), local('Campton-MediumItalic'),
        url('Campton-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-MediumItalic.woff2') format('woff2'),
        url('Campton-MediumItalic.woff') format('woff'),
        url('Campton-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-SemiBold.eot');
    src: local('Campton SemiBold'), local('Campton-SemiBold'),
        url('Campton-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Campton-SemiBold.woff2') format('woff2'),
        url('Campton-SemiBold.woff') format('woff'),
        url('Campton-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Campton Book';
    src: url('Campton-BoldItalic.eot');
    src: local('Campton Bold Italic'), local('Campton-BoldItalic'),
        url('Campton-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-BoldItalic.woff2') format('woff2'),
        url('Campton-BoldItalic.woff') format('woff'),
        url('Campton-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-BlackItalic.eot');
    src: local('Campton Black Italic'), local('Campton-BlackItalic'),
        url('Campton-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-BlackItalic.woff2') format('woff2'),
        url('Campton-BlackItalic.woff') format('woff'),
        url('Campton-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-SemiBoldItalic.eot');
    src: local('Campton SemiBold Italic'), local('Campton-SemiBoldItalic'),
        url('Campton-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-SemiBoldItalic.woff2') format('woff2'),
        url('Campton-SemiBoldItalic.woff') format('woff'),
        url('Campton-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Campton Book';
    src: url('Campton-Bold.eot');
    src: local('Campton Bold'), local('Campton-Bold'),
        url('Campton-Bold.eot?#iefix') format('embedded-opentype'),
        url('Campton-Bold.woff2') format('woff2'),
        url('Campton-Bold.woff') format('woff'),
        url('Campton-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-LightItalic.eot');
    src: local('Campton Light Italic'), local('Campton-LightItalic'),
        url('Campton-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-LightItalic.woff2') format('woff2'),
        url('Campton-LightItalic.woff') format('woff'),
        url('Campton-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-ExtraLight.eot');
    src: local('Campton ExtraLight'), local('Campton-ExtraLight'),
        url('Campton-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Campton-ExtraLight.woff2') format('woff2'),
        url('Campton-ExtraLight.woff') format('woff'),
        url('Campton-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-Thin.eot');
    src: local('Campton Thin'), local('Campton-Thin'),
        url('Campton-Thin.eot?#iefix') format('embedded-opentype'),
        url('Campton-Thin.woff2') format('woff2'),
        url('Campton-Thin.woff') format('woff'),
        url('Campton-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-ExtraBoldItalic.eot');
    src: local('Campton ExtraBold Italic'), local('Campton-ExtraBoldItalic'),
        url('Campton-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Campton-ExtraBoldItalic.woff2') format('woff2'),
        url('Campton-ExtraBoldItalic.woff') format('woff'),
        url('Campton-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

